import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import { urlBaseImgStatic } from '../helpers/url';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    app_bar: {
        backgroundColor: 'var(--kpvsBrown)',
        zIndex: 99,
    },
    toolbar: {
        minHeight: '50px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function TopbarM(props) {
    const token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    const path = window.location.pathname.split('/');
    const [state_title_page, set_title_page] = useState('');
    const [state_show, set_show] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        switch (path[1]) {
            case 'login':
                set_show(false);
                set_title_page('');
            break;
            case '':
                set_show(true);
                set_title_page('首頁');
            break;

            case 'staff_list':
                set_show(true);
                set_title_page('老師檢視');
            break;
            case 'staff_edit':
                set_show(true);
                set_title_page('老師資料編輯');
            break;
            case 'staff_view':
                set_show(true);
                set_title_page('老師資料檢視');
            break;
            case 'staff_add':
                set_show(true);
                set_title_page('老師新增');
            break;
            case 'asset_mgmt':
                set_show(true);
                set_title_page('資產管理');
            break;
            case 'asset_owner':
                set_show(true);
                set_title_page('保管資產');
            break;
            case 'weixiu_admin':
                set_show(true);
                set_title_page('資產維修管理');
            break;
            case 'vendor_mgmt':
                set_show(true);
                set_title_page('廠商管理');
            break;

            
            case 'registration_student_list':
                set_show(true);
                set_title_page('學生作業');
            break;
            case 'registration_student_edit':
                set_show(true);
                set_title_page('學生資料編輯');
            break;
            case 'registration_student_view':
                set_show(true);
                set_title_page('學生資料檢視');
            break;
            case 'registration_student_banji_list':
                set_show(true);
                set_title_page('班級作業');
            break;
            case 'registration_student_add':
                set_show(true);
                set_title_page('學生新增');
            break;
            case 'registration_student_export':
                set_show(true);
                set_title_page('資料匯出');
            break;
            case 'registration_import_prosp_student':
                set_show(true);
                set_title_page('招生建檔匯入');
            break;
            case 'registration_prosp_student_list':
                set_show(true);
                set_title_page('招生作業');
            break;
            case 'registration_prosp_student_view':
                set_show(true);
                set_title_page('招生檢視');
            break;
            case 'registration_prosp_student_edit':
                set_show(true);
                set_title_page('招生編輯');
            break;
            case 'registration_prosp_student_fill_info':
                set_show(false);
                set_title_page('填寫學生資料');
            break;
            case 'registration_prosp_student_baodao':
                set_show(false);
                set_title_page('網路報到');
            break;
            case 'registration_student_sms_fill_info':
                set_show(true);
                set_title_page('填寫資料簡訊');
            break;
            case 'registration_student_fill_info':
                set_show(false);
                set_title_page('填寫學生資料');
            break;
            case 'registration_scholarship_admin':
                set_show(true);
                set_title_page('獎學金作業');
            break;


            case 'service_general_msg_delivery':
                set_show(true);
                set_title_page('訊息發送');
            break;
            case 'service_general_doc_apply':
                set_show(true);
                set_title_page('文件申請');
            break;


            case 'admission_prosp_student_list':
                set_show(true);
                set_title_page('錄取學生作業');
            break;
            case 'admission_prosp_student_add':
                set_show(true);
                set_title_page('招生新增');
            break;
            case 'admission_prosp_student_import':
                set_show(true);
                set_title_page('招生建檔匯入');
            break;
            case 'admission_prosp_student_sem1':
                set_show(true);
                set_title_page('說明會作業');
            break;
            case 'admission_prosp_student_fill_info':
                set_show(false);
                set_title_page('填寫學生資料');
            break;
            case 'admission_export':
                set_show(true);
                set_title_page('資料匯出');
            break;


            case 'sms_list':
                set_show(true);
                set_title_page('SMS簡訊檢視');
            break;
            case 'sms_free':
                set_show(true);
                set_title_page('SMS簡訊發送');
            break;


            case 'hsiabei_checker':
                set_show(true);
                set_title_page('蝦米網 - 審核');
            break;
            case 'hsiabei_admin':
                set_show(true);
                set_title_page('蝦米網 - 設定');
            break;
            case 'hsiabei':
                set_show(true);
                set_title_page('蝦米網');
            break;


            case 'weixiu':
                set_show(true);
                set_title_page('資產維修報送');
            break;

            
            case 'admin_data_manage':
                set_show(true);
                set_title_page('資料管理');
            break;
            case 'admin_guardian_event_manage':
                set_show(true);
                set_title_page('家長活動管理');
            break;


            case 'kpce':
                set_show(false);
            break;
            case 'kpce_admin':
                set_show(true);
                set_title_page('推廣部 - 行政作業');
            break;
            case 'kpce_touke_import':
                set_show(true);
                set_title_page('推廣部 - 投課匯入');
            break;


            case 'student_zhiping':
                set_show(true);
                set_title_page('質性評量紀錄');
            break;
            case 'student_guardian_event_result':
                set_show(true);
                set_title_page('家長活動時數');
            break;
            case 'student_barcode':
                set_show(true);
                set_title_page('個人條碼');
            break;
            case 'student_scholarship_apply':
                set_show(true);
                set_title_page('獎學金申請');
            break;


            case 'profile':
                set_show(true);
                set_title_page('個人設定');
            break;
            case 'redirect':
                set_title_page('');
            break;
            default:
        }    
    });

    let contents = null;
    if (path[1] === 'admission_prosp_student_fill_info' || path[1] === 'registration_prosp_student_baodao' || path[1] === 'registration_student_fill_info' || path[1] === 'registration_prosp_student_fill_info' || path[1] === 'redirect') {
        // this one skips the burger menu selector
        contents = (
            <Background>
                <Container2>
                    <div>
                        <img alt="Logo" src={ urlBaseImgStatic + "/logo/logo_trans_001-380x95.png" } width="180px" />
                        <div style={{ display: 'inline-block', verticalAlign: 'super', marginLeft: '2rem', fontSize: '1.5rem' }}>{ state_title_page }</div>
                    </div>
                </Container2>
            </Background>
        );
    }
    else if (state_show && token_decoded) {
        if (token_decoded.can_admission_query) {
            // this one skips the burger menu selector
            contents = (
                <Background>
                    <Container2>
                        <div>
                            <img alt="Logo" src={ urlBaseImgStatic + "/logo/logo_trans_001-380x95.png" } width="180px" />
                            <div style={{ display: 'inline-block', verticalAlign: 'super', marginLeft: '2rem', fontSize: '1.5rem' }}>{ state_title_page }</div>
                        </div>
                    </Container2>
                </Background>
            );
        }
        else if (token_decoded.is_staff || token_decoded.is_student) {
            contents = (
                <div className={classes.root}>
                <AppBar position="fixed" className={classes.app_bar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton edge="start" onClick={() => props.on_click_bar_icon()} className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <div style={{marginRight: '1rem', cursor: 'pointer', display: path[1] === '' ? 'none' : 'block'}} onClick={() => props.on_click_back()}><FontAwesomeIcon icon={faArrowLeft} size="lg" /></div>
                        <Typography variant="h6" className={classes.title}>
                            {state_title_page}
                        </Typography>
                        <Box>
                            {token_decoded.name_full_zh}
                        </Box>
                    </Toolbar>
                </AppBar>
                </div>
            );
        }
    }

    return contents;
}

const Background = styled.div`
    width: 100%;
    height: 3rem;
    background-color: var(--kpvsBrown);
    color: #FFFFFF;
    position: fixed;
    top: 0;
    z-index: 2;
`;

const Container2 = styled.div`
    width: 98%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (min-width: 800px) {
        max-width: 800px;
    }
`;