import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    vendor_main_query_page: 0,
    vendor_main_query_rows_per_page: 20,
    vendor_main_query_query: '',
    vendor_main_query_order_by: 'name_zh ASC',

    asset_main_query_page: 0,
    asset_main_query_rows_per_page: 20,
    asset_main_query_type: [],
    asset_main_query_is_scrap: 0,
    asset_main_query_order_by: 'name_zh ASC',
    asset_main_query_query: '',
    asset_main_scrap_query_page: 0,
    asset_main_scrap_query_rows_per_page: 50,

    asset_owner_query_page: 0,
    asset_owner_query_rows_per_page: 20,
    asset_owner_scrap_query_page: 0,
    asset_owner_scrap_query_rows_per_page: 50,
    asset_owner_reassign_query_page: 0,
    asset_owner_reassign_query_rows_per_page: 50,

    weixiu_main_query_page: 0,
    weixiu_main_query_rows_per_page: 20,
    weixiu_main_query_query: '',
    weixiu_main_query_status: 0,
    weixiu_main_query_order_by: 'created_on DESC',

    weixiu_main_manager_query_page: 0,
    weixiu_main_manager_query_rows_per_page: 20,
    weixiu_main_manager_query_query: '',
    weixiu_main_manager_query_order_by: 'created_on DESC',
};

const set_vendor_main_query_page = (state, action) => {
    return updateObject(state, {
        vendor_main_query_page: action.data,
    });
};

const set_vendor_main_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        vendor_main_query_rows_per_page: action.data,
    });
};

const set_vendor_main_query_query = (state, action) => {
    return updateObject(state, {
        vendor_main_query_query: action.data,
    });
};

const set_vendor_main_query_order_by = (state, action) => {
    return updateObject(state, {
        vendor_main_query_order_by: action.data,
    });
};

const set_asset_main_query_page = (state, action) => {
    return updateObject(state, {
        asset_main_query_page: action.data,
    });
};

const set_asset_main_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        asset_main_query_rows_per_page: action.data,
    });
};

const set_asset_main_query_type = (state, action) => {
    return updateObject(state, {
        asset_main_query_type: action.data,
    });
};

const set_asset_main_query_is_scrap = (state, action) => {
    return updateObject(state, {
        asset_main_query_is_scrap: action.data,
    });
};

const set_asset_main_query_order_by = (state, action) => {
    return updateObject(state, {
        asset_main_query_order_by: action.data,
    });
};

const set_asset_main_query_query = (state, action) => {
    return updateObject(state, {
        asset_main_query_query: action.data,
    });
};

const set_asset_main_scrap_query_page = (state, action) => {
    return updateObject(state, {
        asset_main_scrap_query_page: action.data,
    });
};

const set_asset_main_scrap_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        asset_main_scrap_query_rows_per_page: action.data,
    });
};

const set_asset_owner_query_page = (state, action) => {
    return updateObject(state, {
        asset_owner_query_page: action.data,
    });
};

const set_asset_owner_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        asset_owner_query_rows_per_page: action.data,
    });
};

const set_asset_owner_scrap_query_page = (state, action) => {
    return updateObject(state, {
        asset_owner_scrap_query_page: action.data,
    });
};

const set_asset_owner_scrap_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        asset_owner_scrap_query_rows_per_page: action.data,
    });
};

const set_asset_owner_reassign_query_page = (state, action) => {
    return updateObject(state, {
        asset_owner_reassign_query_page: action.data,
    });
};

const set_asset_owner_reassign_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        asset_owner_reassign_query_rows_per_page: action.data,
    });
};

const set_weixiu_main_query_page = (state, action) => {
    return updateObject(state, {
        weixiu_main_query_page: action.data,
    });
};

const set_weixiu_main_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        weixiu_main_query_rows_per_page: action.data,
    });
};

const set_weixiu_main_query_query = (state, action) => {
    return updateObject(state, {
        weixiu_main_query_query: action.data,
    });
};

const set_weixiu_main_query_status = (state, action) => {
    return updateObject(state, {
        weixiu_main_query_status: action.data,
    });
};

const set_weixiu_main_query_order_by = (state, action) => {
    return updateObject(state, {
        weixiu_main_query_order_by: action.data,
    });
};

const set_weixiu_main_manager_query_page = (state, action) => {
    return updateObject(state, {
        weixiu_main_manager_query_page: action.data,
    });
};

const set_weixiu_main_manager_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        weixiu_main_manager_query_rows_per_page: action.data,
    });
};

const set_weixiu_main_manager_query_query = (state, action) => {
    return updateObject(state, {
        weixiu_main_manager_query_query: action.data,
    });
};

const set_weixiu_main_manager_query_order_by = (state, action) => {
    return updateObject(state, {
        weixiu_main_manager_query_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_VENDOR_MAIN_QUERY_PAGE: return set_vendor_main_query_page(state, action);
        case actionTypes.SET_VENDOR_MAIN_QUERY_ROWS_PER_PAGE: return set_vendor_main_query_rows_per_page(state, action);
        case actionTypes.SET_VENDOR_MAIN_QUERY_QUERY: return set_vendor_main_query_query(state, action);
        case actionTypes.SET_VENDOR_MAIN_QUERY_ORDER_BY: return set_vendor_main_query_order_by(state, action);

        case actionTypes.SET_ASSET_MAIN_QUERY_PAGE: return set_asset_main_query_page(state, action);
        case actionTypes.SET_ASSET_MAIN_QUERY_ROWS_PER_PAGE: return set_asset_main_query_rows_per_page(state, action);
        case actionTypes.SET_ASSET_MAIN_QUERY_TYPE: return set_asset_main_query_type(state, action);
        case actionTypes.SET_ASSET_MAIN_QUERY_IS_SCRAP: return set_asset_main_query_is_scrap(state, action);
        case actionTypes.SET_ASSET_MAIN_QUERY_ORDER_BY: return set_asset_main_query_order_by(state, action);
        case actionTypes.SET_ASSET_MAIN_QUERY_QUERY: return set_asset_main_query_query(state, action);
        case actionTypes.SET_ASSET_MAIN_SCRAP_QUERY_PAGE: return set_asset_main_scrap_query_page(state, action);
        case actionTypes.SET_ASSET_MAIN_SCRAP_QUERY_ROWS_PER_PAGE: return set_asset_main_scrap_query_rows_per_page(state, action);

        case actionTypes.SET_ASSET_OWNER_QUERY_PAGE: return set_asset_owner_query_page(state, action);
        case actionTypes.SET_ASSET_OWNER_QUERY_ROWS_PER_PAGE: return set_asset_owner_query_rows_per_page(state, action);
        case actionTypes.SET_ASSET_OWNER_SCRAP_QUERY_PAGE: return set_asset_owner_scrap_query_page(state, action);
        case actionTypes.SET_ASSET_OWNER_SCRAP_QUERY_ROWS_PER_PAGE: return set_asset_owner_scrap_query_rows_per_page(state, action);
        case actionTypes.SET_ASSET_OWNER_REASSIGN_QUERY_PAGE: return set_asset_owner_reassign_query_page(state, action);
        case actionTypes.SET_ASSET_OWNER_REASSIGN_QUERY_ROWS_PER_PAGE: return set_asset_owner_reassign_query_rows_per_page(state, action);

        case actionTypes.SET_WEIXIU_MAIN_QUERY_PAGE: return set_weixiu_main_query_page(state, action);
        case actionTypes.SET_WEIXIU_MAIN_QUERY_ROWS_PER_PAGE: return set_weixiu_main_query_rows_per_page(state, action);
        case actionTypes.SET_WEIXIU_MAIN_QUERY_QUERY: return set_weixiu_main_query_query(state, action);
        case actionTypes.SET_WEIXIU_MAIN_QUERY_STATUS: return set_weixiu_main_query_status(state, action);
        case actionTypes.SET_WEIXIU_MAIN_QUERY_ORDER_BY: return set_weixiu_main_query_order_by(state, action);

        case actionTypes.SET_WEIXIU_MAIN_MANAGER_QUERY_PAGE: return set_weixiu_main_manager_query_page(state, action);
        case actionTypes.SET_WEIXIU_MAIN_MANAGER_QUERY_ROWS_PER_PAGE: return set_weixiu_main_manager_query_rows_per_page(state, action);
        case actionTypes.SET_WEIXIU_MAIN_MANAGER_QUERY_QUERY: return set_weixiu_main_manager_query_query(state, action);
        case actionTypes.SET_WEIXIU_MAIN_MANAGER_QUERY_ORDER_BY: return set_weixiu_main_manager_query_order_by(state, action);
        default: return state;
    };
};

export default reducer;