import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer_admin from './store/reducers/admin';
import reducer_admission from './store/reducers/admission';
import reducer_houqin from './store/reducers/houqin';
import reducer_registration from './store/reducers/registration';
import reducer_staff from './store/reducers/staff';
import reducer_kpce from './store/reducers/kpce';

const compose_enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer_root = combineReducers({
    admin: reducer_admin,
    admission: reducer_admission,
    houqin: reducer_houqin,
    registration: reducer_registration,
    staff: reducer_staff,
    kpce: reducer_kpce,
});
const store = createStore(reducer_root, compose_enhancers(applyMiddleware(thunk)));
const theme = createTheme({
    typography: {
        // fontSize: '16px',
        fontFamily: 'Noto Sans TC, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    },
});

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <ToastContainer />
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
